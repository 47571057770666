import { FC, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { useNavigate } from 'react-router-dom';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';

import { Alert, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { Responses } from '@app/lib/types';
import { ContestModel, UpdateContestForm } from '@app/data/contest';
import { isSuccess } from '@app/lib/rest';

interface Props {
  userContest?: Responses.UserContest;
}

export const ContestInfo: FC<Props> = ({ userContest }) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(false);

  const [name, setName] = useState<string>(userContest?.contest.name ?? '');
  const onNameChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
    onSlugChanged(event);
  };

  const [slug, setSlug] = useState<string>(userContest?.contest.slug ?? '');
  const onSlugChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const slug = event.target.value.toLowerCase().replace(/[^a-z0-9]+/g, '-').replace(/(^-|-$)/g, '');
    setSlug(slug);
  };

  const [startTime, setStartTime] = useState<Dayjs | null>(userContest?.contest.startTime ? dayjs(userContest.contest.startTime) : null);
  const onStartTimeAccepted = (date: Dayjs | null) => setStartTime(date);

  const [endTime, setEndTime] = useState<Dayjs | null>(userContest?.contest.endTime ? dayjs(userContest.contest.endTime) : null);
  const onEndTimeAccepted = (date: Dayjs | null) => setEndTime(date);

  const [threshold, setThreshold] = useState<number>(userContest?.contest.threshold ?? 25);
  const onThresholdChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (isNaN(parseInt(event.target.value))) {
      setThreshold(0);
      return;
    }

    setThreshold(parseInt(event.target.value));
  };

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setError(undefined);
    setLoading(true);
    setShowSuccessAlert(false);

    const body: UpdateContestForm = {
      name: name,
      slug: slug,
      startTime: startTime?.utc().toISOString() ?? undefined,
      endTime: endTime?.utc().toISOString() ?? undefined,
      threshold: threshold
    };

    const result = ContestModel.validateUpdate(body);
    if (result instanceof Error) {
      setError(result.message);
      setLoading(false);
      return;
    }

    if (userContest == null) {
      setError('Unexpected error occurred. Please refresh the page.');
      setLoading(false);
      return;
    }

    const resp = await ContestModel.update(userContest.contest.slug, result);
    if (isSuccess(resp)) {
      setLoading(false);
      setShowSuccessAlert(true);

      if (userContest.contest.slug !== slug) {
        navigate(`/contest/${slug}`, { replace: true });
      }
    } else {
      setError(resp.message);
      setLoading(false);
    }
  };

  return (
    <>
      <p className='contests-page__section-title'>Contest Information</p>
      <div>
        <form onSubmit={onSubmit}>
          <div className='spacer-16'>
            <TextField label='Name' fullWidth value={name}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => { onNameChanged(e); }} />
          </div>
          <div className='spacer-16'>
            <TextField label='URL Slug' fullWidth value={slug}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => { onSlugChanged(e); }} />
            <p className='form-hint'>
              Contest URL is {`${process.env.REACT_APP_WEB_HOST}/contest/${slug ?? '{slug}'}`}
            </p>
          </div>
          <div className='spacer-16'>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker label='Start Date' className='create-contest-page__date-field' value={startTime}
                onAccept={onStartTimeAccepted} />
            </LocalizationProvider>
          </div>
          <div className='spacer-16'>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker label='End Date' className='create-contest-page__date-field' value={endTime}
                onAccept={onEndTimeAccepted} />
            </LocalizationProvider>
          </div>
          <div className='spacer-16'>
            <TextField label='Flagged Play Threshold' fullWidth value={threshold}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => { onThresholdChanged(e); }} />
          </div>
          <div className='alert-container'>
            {error != null &&
            <Alert severity='error' className='alert-full  spacer-16'>{error}</Alert>
            }
            {showSuccessAlert &&
            <Alert severity='success' className='alert-full spacer-16'>Contest updated successfully</Alert>
            }
          </div>
          <div className='spacer-16 account-page__form-button'>
            <LoadingButton type='submit' variant='contained' loading={loading} disableElevation>
              Update
            </LoadingButton>
          </div>
        </form>
      </div>
    </>
  );
};
