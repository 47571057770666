import { QueryClient } from "@tanstack/react-query";

import { ContestApi, Result, isSuccess } from "@app/lib/rest";
import { Contest, Requests, Responses } from "@app/lib/types";
import { getRequestConfig } from "./header";

export interface CreateContestForm {
  name?: string;
  slug?: string;
  startTime?: string;
  endTime?: string;
}

export interface UpdateContestForm {
  name?: string;
  slug?: string;
  startTime?: string;
  endTime?: string;
  threshold?: number;
}

export namespace ContestModel {
  export const create = async (body: Requests.CreateContest): Promise<Result<Contest>> => {
    const res = await ContestApi.create(body, getRequestConfig());
    if (isSuccess(res)) {
      const queryClient = new QueryClient();
      queryClient.invalidateQueries({ queryKey: ['contests'] });
    }

    return res;
  };

  export const validateCreate = (form: CreateContestForm): Requests.CreateContest | Error => {
    if (form.name == null || form.name === '') {
      return new Error('Name is required.');
    }

    if (form.slug == null || form.slug === '') {
      return new Error('URL Slug is required.');
    }

    return {
      name: form.name,
      slug: form.slug,
      startTime: form.startTime,
      endTime: form.endTime,
    }
  };

  export const update = async (slug: string, body: Requests.UpdateContest): Promise<Result<Contest>> => {
    const res = await ContestApi.update(slug, body, getRequestConfig());
    if (isSuccess(res)) {
      const queryClient = new QueryClient();
      queryClient.invalidateQueries({ queryKey: ['contests'] });
    }

    return res;
  };

  export const validateUpdate = (form: UpdateContestForm): Requests.UpdateContest | Error => {
    if (form.name == null || form.name === '') {
      return new Error('Name is required.');
    }

    if (form.slug == null || form.slug === '') {
      return new Error('URL Slug is required.');
    }

    if (form.threshold == null || form.threshold < 0 || form.threshold > 100) {
      return new Error('Flagged play threshold must be between 0 and 100');
    }

    return {
      name: form.name,
      slug: form.slug,
      startTime: form.startTime,
      endTime: form.endTime,
      threshold: form.threshold
    }
  };

  export const remove = async (slug: string): Promise<Result<boolean>> => {
    const res = await ContestApi.remove(slug, getRequestConfig());
    if (isSuccess(res)) {
      const queryClient = new QueryClient();
      queryClient.invalidateQueries({ queryKey: ['contests'] });
    }

    return res;
  };

  export const list = async (): Promise<Result<Responses.UserContest[]>> => {
    return await ContestApi.list(getRequestConfig());
  };

  export const get = async (slug: string): Promise<Result<Responses.UserContest>> => {
    return await ContestApi.get(slug, getRequestConfig());
  };
}
