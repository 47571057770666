import { FC, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Container from '@mui/material/Container';

import { SiteBar } from '@app/components/site-bar';
import { useContest } from '@app/hooks/contest';
import { LoadingView } from '@app/components/loading';
import { ContestInfo } from './info';
import { ContestDangerZone } from './danger';
import { AuditorList } from './auditor-list';
import { useAuditors } from '@app/hooks/auditor';

export const ContestSettingsPage: FC = () => {
  const navigate = useNavigate();

  const { contestSlug } = useParams<{ contestSlug: string }>();
  const { data: userContest, isError, isLoading } = useContest(contestSlug);
  const { data: auditors } = useAuditors(contestSlug);

  useEffect(() => {
    if (isError && !isLoading) {
      navigate('/');
    }
  }, [isError, isLoading, navigate]);

  return (
    <div>
      <SiteBar title={`${userContest?.contest.name ?? 'Contest'} Settings`} showActions={true} />
      <Container maxWidth='sm'>
        {isLoading ?
        <LoadingView message='Loading contest information' />
        :
        <>
          <ContestInfo userContest={userContest} />
          <AuditorList auditors={auditors ?? []} isOwner={userContest?.isOwner ?? false} />
          <ContestDangerZone slug={userContest?.contest.slug} />
        </>
        }
      </Container>
    </div>
  );
};
